@import "~bootstrap/scss/bootstrap";

* {
  scroll-margin-top: 100px;
}

label {
  display: block;
}

input[type=text] {
  width: 100%;
  padding: 0.5rem;
}

.pss-choice  input[type=checkbox] {
  transform: scale(1.5);
  margin-right: 1rem !important;
  margin-top: 1rem !important;
}

.pss-choice {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.pss-part > .pss-choice:nth-of-type(odd) {
  background-color: #EEE;
}

.card-header.pss-part.negative {
  background-color: $orange-200;
  font-weight: bold;
}

.card-header.pss-part.positive {
  background-color: $green-200;
  font-weight: bold;
}

.card-body.pss-part {
  padding: 0 !important;
}